






























































import { CONDITIONS_ICON, CONDITIONS_TYPE } from "@/helpers/conditions";
import { CalendarJob } from "@/models/entities/calendar-job.interface";
import format from "date-fns/format";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CJobCard extends Vue {
  @Prop({ required: false, default: null, type: Object })
  private job: CalendarJob;
  @Prop({ required: false, default: false, type: Boolean })
  private isSelected: boolean;

  private getDate(date: string): string {
    return date && date.trim() !== ""
      ? format(new Date(date), "dd/MM/yyyy")
      : "--";
  }

  private getTime(start, end): string {
    const s = start && start.trim() !== "" ? this.formatTime(start) : "--:--";
    const e = end && end.trim() !== "" ? this.formatTime(end) : "--:--";
    return s + " - " + e;
  }

  private formatTime(time: string): string {
    return format(new Date(time), "HH:mm");
  }

  private getChipIcon(type: string): string {
    return CONDITIONS_ICON(type);
  }

  private getChipType(type: string): string {
    return CONDITIONS_TYPE(type);
  }
}
